<template>
    <main class="main-page">
    <section class="page-section " >
        <div class="container">
            <div class="grid ">
                <div  class="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <template v-if="pageReady">
                            <form ref="observer"  tag="form" @submit.prevent="submitForm()" :class="{ 'card': !isSubPage }" class="">
                                <!--[form-content-start]-->
                                <div class="grid">
                                    <div class="col-12">
                                        <div class="formgrid grid">
                                            <div class="col-12 md:col-3">
                                                First Name *
                                            </div>
                                            <div class="col-12 md:col-9">
                                                <InputText ref="ctrlfirst_name" v-model.trim="formData.first_name"  label="First Name" type="text" placeholder="Enter First Name"      
                                                class=" w-full" :class="getErrorClass('first_name')">
                                                </InputText>
                                                <small v-if="isFieldValid('first_name')" class="p-error">{{ getFieldError('first_name') }}</small> 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="formgrid grid">
                                            <div class="col-12 md:col-3">
                                                Middle Name 
                                            </div>
                                            <div class="col-12 md:col-9">
                                                <InputText ref="ctrlmiddle_name" v-model.trim="formData.middle_name"  label="Middle Name" type="text" placeholder="Enter Middle Name"      
                                                class=" w-full" :class="getErrorClass('middle_name')">
                                                </InputText>
                                                <small v-if="isFieldValid('middle_name')" class="p-error">{{ getFieldError('middle_name') }}</small> 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="formgrid grid">
                                            <div class="col-12 md:col-3">
                                                Last Name *
                                            </div>
                                            <div class="col-12 md:col-9">
                                                <InputText ref="ctrllast_name" v-model.trim="formData.last_name"  label="Last Name" type="text" placeholder="Enter Last Name"      
                                                class=" w-full" :class="getErrorClass('last_name')">
                                                </InputText>
                                                <small v-if="isFieldValid('last_name')" class="p-error">{{ getFieldError('last_name') }}</small> 
                                            </div>
                                        </div>
                                    </div>
									<div class="col-12">
                                        <div class="formgrid grid">
                                            <div class="col-12 md:col-3">
                                                Photo 
                                            </div>
                                            <div class="col-12 md:col-9">
                                                <div class="mb-3">
                                                    <Uploader :class="getErrorClass('photo')" :auto="true" :fileLimit="1" :maxFileSize="3000000" accept=".jpg,.png,.gif,.jpeg" :multiple="false" style="width:100%" label="Choose files or drop files here" upload-path="fileuploader/upload/photo" v-model="formData.photo"></Uploader>
                                                </div>
                                                <small v-if="isFieldValid('photo')" class="p-error">{{ getFieldError('photo') }}</small> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--[form-content-end]-->
                                <div v-if="showSubmitButton" class="text-center my-3">
                                    <Button type="submit" label="Update" icon="pi pi-send" :loading="saving" />
                                </div>
                            </form>
                        </template>
                        <template v-if="loading">
                            <div class="p-3 text-center">
                                <ProgressSpinner style="width:50px;height:50px" />
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </main>
</template>
<script setup>
	import {  computed,  reactive, toRefs, onMounted } from 'vue';
	import useVuelidate from '@vuelidate/core';
	import { required } from '@/services/validators';
	import { useApp } from '@/composables/app.js';
	import { useEditPage } from '@/composables/editpage.js';
	const props = defineProps({
		id: [String, Number],
		pageName: {
			type: String,
			default: 'users',
		},
		routeName: {
			type: String,
			default: 'usersaccountedit',
		},
		pagePath: {
			type : String,
			default : 'account/edit',
		},
		apiPath: {
			type: String,
			default: 'account/edit',
		},
		submitButtonLabel: {
			type: String,
			default: "Update",
		},
		formValidationError: {
			type: String,
			default: "Form is invalid",
		},
		formValidationMsg: {
			type: String,
			default: "Please complete the form",
		},
		msgTitle: {
			type: String,
			default: 'Update Record',
		},
		msgBeforeSave: {
			type: String,
			default: "",
		},
		msgAfterSave: {
			type: String,
			default: "Record updated successfully",
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showSubmitButton: {
			type: Boolean,
			default: true,
		},
		redirect: {
			type : Boolean,
			default : true,
		},
		isSubPage: {
			type : Boolean,
			default : false,
		},
	});
	const app = useApp();
	const formDefaultValues = Object.assign({
		photo: "", 
		first_name: "", 
		middle_name: "", 
		last_name: "", 
	}, props.pageData);
	const formData = reactive({ ...formDefaultValues });
	function afterSubmit(response) {
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		location.reload();
	}
	const rules = computed(() => {
		return {
			photo: {  },
			first_name: { required },
			middle_name: {  },
			last_name: { required }
		}
	});
	const v$ = useVuelidate(rules, formData); //form validation
	const page = useEditPage({ props, formData, v$, afterSubmit });
	const { submitted, saving, loading, pageReady } = toRefs(page.state);
	const { apiUrl, currentRecord } = page.computedProps;
	const { load, submitForm, getErrorClass, getFieldError, isFieldValid, mapOptionField } = page.methods;
	onMounted(()=>{
		const pageTitle = "My Account";
		app.setPageTitle(props.routeName, pageTitle); // set browser page title
		load();
	});
</script>
<style scoped>
</style>
